<template>
    <div class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="status"
        />

        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="entities"
            :item-class="itemRowBackground"
            :options.sync="options"
            :server-items-length="total"
            :items-per-page="10"
            :footer-props="{ itemsPerPageOptions:[10, 20, 30] }"
            :page.sync="options.page"
            @page-count="paginationPageCount = $event"
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'None.Meeting' })"
        >
        <!--@dblclick:row="(event, data) => $router.push({ name: 'Meeting', params: { id: data.item.id } })"-->
            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.event_date`]="{ item }">
                <div>{{ dateFormat(item.event_date, 'DD.MM.YYYY') }}&nbsp;&nbsp;&nbsp;{{ $moment(item.begin_time, 'HH:mm:ss').format('HH:mm') }} - {{ $moment(item.end_time, 'HH:mm:ss').format('HH:mm') }}</div>
            </template>
            
            <template v-slot:[`item.subject`]="{ item }">
                <div class="elipsis-text-wrap">
                    <v-doc-type-chip type='Meeting' :text="$t('Совещание')" />                        
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.subject">
                            {{ item.subject }}
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:[`item.initiatorname`]="{ item }">
                <v-workplace-chip v-if="item.initiatorworkplaceid" :id="item.initiatorworkplaceid" :name="item.initiatorname" />
            </template>

            <template v-slot:[`item.secretaryname`]="{ item }">
                <v-workplace-chip v-if="item.secretaryworkplaceid" :id="item.secretaryworkplaceid" :name="item.secretaryname" />
                            </template>

            <template v-slot:[`item.workstatus`]="{ item }">
                <StatusChip :status="avaibleStatuses[$helpers.getEventStatus(item.status == 1 ? 0 : item.workstatus)]"/>
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>

        </v-data-table>

    </div>
</template>

<script>
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import Axios from 'axios';
import _ from 'lodash';
import sys from '@/services/system';
import SearchPanel from '@/components/saylau/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import StatusChip from '@/components/global/chips/StatusChip';
import Toolbar from '@/components/documents/Toolbar.vue';

export default {
    name: "MeetingsList",
    components: {
        SearchPanel,
        StatusChip,
        FilterPanel,
        Toolbar
    },
    columnIndex:  {
        id: 1,
        number: 2,
        event_date: 3,
        begin_time: 4,
        end_time: 5,
        subject: 6,
        place: 7,
        workstatus: 8,
        initiatorname: 10,
        secretaryname: 12,
    },
    computed: {
        search: {
            get: function() {
                return this.$store.getters['eventManagement/list/getSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('eventManagement/list/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('eventManagement/list/SET_SEARCH', v);
            }, 500)
        },
        status: {
            get: function() {
                return this.$store.getters['eventManagement/list/getStatus'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('eventManagement/list/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('eventManagement/list/SET_STATUS', v);
            }, 500)
        },
        headers: {
            get: function() {
                let items = [
                    { text: i18n.t("№"), value: 'number', width: '70px' },
                    { text: i18n.t("Дата_время_совещания"), value: 'event_date', width: '200px' },
                    { text: i18n.t("Тема"), value: 'subject' },
                    { text: i18n.t("Место"), value: 'place', width: '35%'},
                    { text: i18n.t("Председатель"), value: 'initiatorname', width: '200px'},
                    { text: i18n.t("Секретарь"), value: 'secretaryname', width: '200px'},
                ];
                if (this.status === 'ListAll')
                    items.push({ text: i18n.t("Статус"), value: 'workstatus', width: '150px', sortable: false, class: 'status-col'});
                return items;
            }
        },
        options: {
            get: function() {
                return this.$store.getters['eventManagement/list/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('eventManagement/list/SET_TABLE_OPTION', newValue);
            }
        },
        filter () {
            return {
                ...this.options,
                Type: this.status,
                SearchStr: this.search
            }
        },
        avaibleStatuses() {
            //Workstatuses
            // 1 - Запланированно
            // 2 - Выполняется
            // 3 - Проведено
            // 0 - Ошибка расчета статуса
            return this.$store.getters['eventManagement/GetAvaibleStatuses'];
        },
    },
    data: () => {
        return {
            loading: true,
            cancellationTokenSorce: null,
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
            filterDataSource: null
        }
    },
    methods: {
        resetPage() {
            this.$store.commit('eventManagement/list/SET_TABLE_OPTION_PAGE', 1);
        },
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        onToolbarClick(event, btn){
            this[btn.Action](btn);
        },
        async Refresh() {
            await this.getData(true);
        },
        async Export(){
            let filter = JSON.parse(this.prevTableFilterObject);
            delete filter.Limit;
            delete filter.Offset;
            let exportURI = `api/eventmanagement/export?param=${JSON.stringify(filter)}&mode=${process.env.NODE_ENV}`;
            exportURI += `&token=${this.$store.getters['global/auth/getUserInfo']?.Token}`;

            this.$eventBus.$emit('on-get-report', {url: exportURI, text: `Экспорт списка совещаний на ${this.$moment(new Date()).format('DD.MM.YYYY hh_mm')}`, type: 'Xls' });
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;

            await this.loadFilters();

            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/eventmanagement/list?filter=${this.getTableFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.Payload?.data ?? [];
            this.total = response?.data?.Payload?.total ?? 0;

            this.loading = false;
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, Type, SearchStr } = this.filter;

            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "event_date" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 3;

            if (sortColumn === 'event_date') {
                OrderDir = [OrderDir, OrderDir];
                OrderBy = [OrderBy , 4];
            }

            return JSON.stringify({ SearchStr, Type, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage });
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `api/eventmanagement/filter`,
                method: 'GET',
            });

            this.filterDataSource = filterResponse ? filterResponse.data.Payload : null;
        },
        dateFormat: sys.dateFormat,
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        }
    },
    async created() {
        let menu = [
            {
                "Action": "Export",
                "Icon": "doc_send",
                "Items": null,
                "Text": "Экспорт_списка",
                "ToolTip": "Экспорт_списка_совещаний",
                "Type": "Documents",
                Localize: true
            },
            {
                "Action": "Refresh",
                "Icon": "refresh",
                "Items": null,
                "Text": "Обновить",
                "ToolTip": "Обновить_форму",
                "Type": null,
                Localize: true
            }
        ];
        this.$store.commit('documents/toolbar/SET_MENU', { menu });
    },
}
</script>